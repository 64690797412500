import { bind } from "@react-rxjs/core"
import { createSignal } from "@react-rxjs/utils"
import {
  combineLatest,
  distinctUntilChanged,
  map,
  merge,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs"

import { priceAndChange$ } from "@/shared/services/instruments/dailyBars"
import {
  LimitOrderRequest,
  Order,
  OrderService,
  Position,
} from "@/shared/services/TradingGateway"
import { StopOrderRequest } from "@/shared/services/TradingGateway/OrderService"
import { filterNotNull } from "@/shared/utils/filterNotNull"
import { formatOrderAmount } from "@/shared/utils/formatNumber"

import { openConfirmDialog } from "../dialog/dialog.service"
import { instruments$ } from "../instruments"
import { positions$ } from "../positions/positions"

export enum Direction {
  Buy = "buy",
  Sell = "sell",
}

export enum OrderType {
  MarketOrder = "market",
  LimitOrder = "limit",
  StopOrder = "stop",
  StopLimitOrder = "stop_limit",
}

export type TradeParams = {
  symbol: string
  direction: Direction
  type: OrderType
}

type InstrumentSymbol = string

const [qty$, setQty] = createSignal<string>()
const [limitPrice$, setLimitPrice] = createSignal<string>()
const [stopPrice$, setStopPrice] = createSignal<string>()
const [timeInForce$, setTimeInForce] = createSignal<string>()
const [reset$, reset] = createSignal()
const [tradeParams$, submit] = createSignal<TradeParams>()
const [direction$, toggleDirection] = createSignal<Direction>()
const [orderType$, toggleOrderType] = createSignal<OrderType>()
const [showTradeReceipt$, setShowTradeReceipt] = createSignal<boolean>()
const [closePositionParams$, closePosition] = createSignal<InstrumentSymbol>()
const [confirmClosePosition$, confirmClosePosition] =
  createSignal<InstrumentSymbol>()

export {
  closePosition,
  reset,
  setLimitPrice,
  setTimeInForce as setLimitTimeInForce,
  setQty,
  setShowTradeReceipt,
  setStopPrice,
  submit,
  toggleDirection,
  toggleOrderType,
}

const qtyInput$ = merge(qty$, reset$.pipe(map(() => "")))
const limitPriceInput$ = merge(limitPrice$, reset$.pipe(map(() => "")))
const stopPriceInput$ = merge(stopPrice$, reset$.pipe(map(() => "")))
const timeInForceInput$ = merge(timeInForce$, reset$.pipe(map(() => "day")))

const [useQtyValue, qtyValue$] = bind(qtyInput$, "")
const [useLimitPriceValue, limitPriceValue$] = bind(limitPriceInput$, "")
const [useStopPriceValue, stopPriceValue$] = bind(stopPriceInput$, "")
const [useTimeInForceValue, timeInForceValue$] = bind(timeInForceInput$, "day")

const [useIndicativeCostValue] = bind((symbol: string) => {
  return qtyValue$.pipe(
    distinctUntilChanged(),
    switchMap((qty) =>
      priceAndChange$(symbol).pipe(
        map((priceChange) => {
          if (qty) {
            const value = parseFloat(qty) * priceChange.price
            return formatOrderAmount(value)
          }
          return "0"
        }),
      ),
    ),
  )
}, "0")

const [useLimitIndicativeCostValue] = bind((symbol: string) => {
  return combineLatest([
    limitPriceValue$,
    qtyValue$,
    tradeDirectionStream$,
  ]).pipe(
    distinctUntilChanged(
      (prev, curr) =>
        prev[0] === curr[0] && prev[1] === curr[1] && prev[2] === curr[2],
    ),
    switchMap(([price, qty, direction]) =>
      priceAndChange$(symbol).pipe(
        map(({ price: priceChange }) => {
          if (qty && price) {
            const limitQty = parseFloat(qty)
            const limitPrice = parseFloat(price)

            if (direction === Direction.Buy && limitPrice > priceChange) {
              return "marketOrder"
            }

            if (direction === Direction.Sell && limitPrice < priceChange) {
              return "marketOrder"
            }

            const value: number = limitQty * limitPrice
            return formatOrderAmount(value)
          }
          return "0"
        }),
      ),
    ),
  )
}, "0")

export {
  useIndicativeCostValue,
  useLimitIndicativeCostValue,
  useLimitPriceValue,
  useQtyValue,
  useStopPriceValue,
  useTimeInForceValue,
}

const closePositionParamsWithDefault$ = closePositionParams$.pipe(
  startWith(null),
)

closePositionParamsWithDefault$
  .pipe(
    filterNotNull(),
    withLatestFrom(instruments$),
    tap(([symbol, instruments]) => {
      const currentInstrument = instruments.find(
        (instrument) => instrument?.symbol === symbol,
      )
      const title = `Are you sure you want to close position for '${
        currentInstrument?.displayName ?? symbol
      }'`

      openConfirmDialog({
        title,
        confirmLabel: `Close ${symbol}`,
        onConfirm: () => confirmClosePosition(symbol),
      })
    }),
  )
  .subscribe()

const closePositionResponse$ = confirmClosePosition$.pipe(
  withLatestFrom(positions$),
  map(([symbol, positions]) =>
    positions.find((position) => position?.symbol === symbol),
  ),
  filterNotNull(),
  switchMap((params: Position) => {
    return OrderService.placeOrder$({
      symbol: params.symbol,
      side: Direction.Sell,
      type: OrderType.MarketOrder,
      qty: params.qty_available,
    })
  }),
)

export const [useClosePositionResponse, _closePositionResponse$] =
  bind<Order | null>(closePositionResponse$, null)

export const [useShowClosePositionModal, showClosePositionModal$] = bind<
  string | null
>(closePositionParamsWithDefault$, null)

const response$ = tradeParams$.pipe(
  withLatestFrom(
    qtyValue$,
    limitPriceValue$,
    timeInForceValue$,
    stopPriceValue$,
  ),
  switchMap(([tradeParams, qty, limitPrice, timeInForce, stopPrice]) => {
    if (tradeParams.type === OrderType.MarketOrder) {
      return OrderService.placeOrder$({
        ...tradeParams,
        side: tradeParams.direction,
        type: tradeParams.type,
        qty,
      })
    } else if (tradeParams.type === OrderType.LimitOrder) {
      const message: LimitOrderRequest = {
        ...tradeParams,
        type: tradeParams.type,
        side: tradeParams.direction,
        qty,
        limitPrice,
        timeInForce,
      }
      return OrderService.placeOrder$(message)
    } else {
      const message: StopOrderRequest = {
        ...tradeParams,
        type: limitPrice ? OrderType.StopLimitOrder : tradeParams.type,
        side: tradeParams.direction,
        qty,
        timeInForce,
        stopPrice,
        limitPrice,
      }
      return OrderService.placeOrder$(message)
    }
  }),
)

export const [useResponse, responseStream$] = bind<{
  isLoading: boolean
  response?: Order
}>(
  merge(
    tradeParams$.pipe(map(() => ({ isLoading: true }))),
    response$.pipe(
      map((response) => ({
        isLoading: false,
        response,
      })),
    ),
  ),
  { isLoading: false },
)

export const [useOrderType] = bind<OrderType>(orderType$, OrderType.MarketOrder)

export const [useTradeDirection, tradeDirectionStream$] = bind<Direction>(
  direction$.pipe(
    map((direction) =>
      direction === Direction.Buy ? Direction.Buy : Direction.Sell,
    ),
  ),
  Direction.Buy,
)

export const [useShowTradeReceipt] = bind<boolean>(
  showTradeReceipt$.pipe(map((showTradeReceipt) => showTradeReceipt)),
  false,
)
