import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { setShowTradeReceipt, useResponse } from "@/shared/services/orders"

import { TradeReceiptInformation } from "./TradeReceiptInformation"

export const OrderReceipt = () => {
  const { t } = useTranslation()
  const { response } = useResponse()

  if (!response) {
    // if we lose the response due to a refresh, close the receipt instead of throwing an error
    setShowTradeReceipt(false)
    return null
  }

  const handleClose = () => {
    setShowTradeReceipt(false)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      paddingX={1}
      data-testid="orderReceipt"
    >
      <TradeReceiptInformation
        symbol={response.order.symbol}
        orderResponse={response}
      />
      <Button
        disableElevation
        onClick={handleClose}
        sx={{
          height: 36,
          borderRadius: 2,
          color: "text.primary",
          backgroundColor: "background.background5",
          "&:hover": {
            backgroundColor: "background.background6",
          },
        }}
        data-testid="tradeReceipt.closeButton"
      >
        <Typography variant="h4" textTransform="uppercase">
          {t("close")}
        </Typography>{" "}
      </Button>
    </Box>
  )
}
