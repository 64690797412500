import { Box, Stack, Tab, Theme } from "@mui/material"
import { t } from "i18next"
import { Link } from "react-router-dom"

import { RootPath } from "@/mobile/paths"
import { IconNames } from "@/shared/components"
import { useRouteMatch } from "@/shared/utils"

import { TabIcon } from "./TabIcon"

interface RouteTab {
  path: string
  icon: IconNames
  label: string
}

const ROUTE_TABS: Record<string, RouteTab> = {
  [RootPath.trade]: {
    path: RootPath.trade,
    icon: "eye",
    label: t("navigation.trade"),
  },
  [RootPath.orderHistory]: {
    path: RootPath.orderHistory,
    icon: "frame",
    label: t("navigation.ordersHistory"),
  },
  [RootPath.positions]: {
    path: RootPath.positions,
    icon: "trend",
    label: t("navigation.positions"),
  },
}

export const Navigation = () => {
  const currentRoute = useRouteMatch(Object.keys(ROUTE_TABS))
  if (currentRoute == null) {
    return null
  }

  const currentTab = ROUTE_TABS[currentRoute]

  return (
    <Stack
      direction="row"
      sx={{
        position: "fixed",
        height: "8%",
        minHeight: "50px",
        maxHeight: "60px",
        width: "100%",
        bottom: 0,
        backgroundColor: (theme: Theme) =>
          `${theme.palette.background.background0}`,
      }}
    >
      {Object.values(ROUTE_TABS).map(({ path, icon, label }) => (
        <Box key={label} width="33%">
          <Tab
            key={path}
            icon={
              <TabIcon
                {...{ label, icon }}
                isSelected={currentTab.path === path}
              />
            }
            sx={{ width: "100%" }}
            value={path}
            to={path}
            component={Link}
          />
        </Box>
      ))}
    </Stack>
  )
}
