import { Box, Button, Select, styled, TextField } from "@mui/material"

import { Direction } from "@/shared/services/orders"

export const OrderTextField = styled(TextField)(
  ({
    theme: {
      palette: { text },
    },
  }) => ({
    input: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      MozAppearance: "textfield",
      color: text.primary,
      padding: 0,
    },
    label: {
      "&.Mui-focused": {
        color: text.secondary,
      },
      color: text.secondary,
    },
  }),
)

export const OrderSelect = styled(Select)(({ theme }) => {
  const {
    palette: { background, action, text },
    spacing,
  } = theme

  return {
    "&.Mui-focused": {
      backgroundColor: background.background1,
    },
    ".MuiSelect-select": {
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    backgroundColor: background.background1,
    "&:hover": {
      backgroundColor: background.background1,
    },
    borderRadius: spacing(1),
    svg: {
      color: action.active,
    },
    color: text.primary,
  }
})

export const OrderBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  backgroundColor: `${palette.background.background10}16`,
  borderRadius: spacing(1),
}))

export const StyledSubmitOrderButton = styled(Button)<{ direction: Direction }>(
  ({ theme }) => ({
    ...theme.typography.h3,
    fontSize: 15,
    height: 36,
    border: "1px solid transparent",
    borderRadius: theme.spacing(2),
    flexShrink: "1",
    textTransform: "uppercase",
    "&.MuiButtonBase-root:disabled": {
      color: theme.palette.action.disabled,
      backgroundColor: "transparent",
      borderColor: theme.palette.action.disabledBackground,
      borderStyle: "solid",
    },
  }),
)
