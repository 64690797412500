import EventBus from "@vertx/eventbus-bridge-client.js"
import { ReplaySubject } from "rxjs"

import { ConnectionState, setEventBusConnectionState } from "./login/signal"

type ServiceEndpoints = Record<string, { streaming: string; cmd: string }>

export const eventBus = new EventBus("/eventbus")
eventBus.enableReconnect(true)
eventBus.onopen = () => {
  setEventBusConnectionState(ConnectionState.CONNECTED)
}

export const serviceStreams$ = new ReplaySubject<ServiceEndpoints>(1)
