import { Stack, Typography } from "@mui/material"
import { ReactNode } from "react"

import { useIsDesktop } from "@/shared/utils"

interface TradeDetailProps {
  label: string
  value: string | number | ReactNode
  direction?: "row" | "column"
  testId: string
}

export const TradeDetail = ({
  label,
  value,
  direction,
  testId,
}: TradeDetailProps) => {
  const isDesktop = useIsDesktop()

  const variant = isDesktop ? "h3" : "h2"

  return (
    <Stack direction={direction} justifyContent="space-between">
      <Typography
        my={isDesktop ? 1 : 0}
        variant={variant}
        color="text.secondary"
      >
        {label}
      </Typography>
      <Typography variant={variant} data-testid={testId}>
        {value}
      </Typography>
    </Stack>
  )
}
