import { Box, Stack } from "@mui/material"
import { useEffect } from "react"

import {
  reset,
  setShowTradeReceipt,
  useResponse,
  useShowTradeReceipt,
} from "@/shared/services/orders"
import { OrderStatus } from "@/shared/services/TradingGateway/OrderService"

import { OrderForm } from "./OrderForm"
import { OrderReceipt } from "./OrderReceipt"

interface Props {
  symbol: string
}

export const OrderContainer = ({ symbol }: Props) => {
  const { isLoading, response } = useResponse()
  const showTradeReceipt = useShowTradeReceipt()

  useEffect(() => {
    if (!isLoading && response) {
      if (response.type === OrderStatus.accepted) {
        reset()
        setShowTradeReceipt(true)
      }
    }
  }, [isLoading, response])

  return (
    <Stack
      flex="1 1 15%"
      minWidth={323}
      p={({ spacing }) => spacing(2)}
      borderRadius={1}
      bgcolor="background.background0"
      sx={{
        height: (theme) => `calc(100vh - ${theme.spacing(8)})`,
        overflow: "scroll",
      }}
    >
      <Box display="flex" flexDirection="column" flex={1}>
        {showTradeReceipt ? <OrderReceipt /> : <OrderForm symbol={symbol} />}
      </Box>
    </Stack>
  )
}
