import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { PropsWithChildren, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Chart, setCurrentSymbol, TimeframeSelector } from "@/shared/components"
import { formatFloat } from "@/shared/utils"

import { DailyBarValue, DailyBarValueProps } from "./DailyBarValue"

interface InstrumentChartProps {
  symbol: string
}

export const InstrumentChart = ({ symbol }: InstrumentChartProps) => {
  useEffect(() => {
    setCurrentSymbol(symbol)

    return () => setCurrentSymbol(undefined)
  }, [symbol])

  return (
    <Stack bgcolor="background.background0" data-testid="InstrumentCharts">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={({ spacing }) => spacing(1, 4)}
      >
        <Ohlc symbol={symbol} />
        <TimeframeSelector />
      </Stack>
      <Chart />
    </Stack>
  )
}

interface OhlcProps {
  symbol: string
}

const Ohlc = ({ symbol }: OhlcProps) => {
  const { t } = useTranslation()
  return (
    <Stack direction="row" gap={1.5}>
      <OhlcField label={t("open")} testId="open">
        <OhlcValue symbol={symbol} field="o" />
      </OhlcField>
      <OhlcField label={t("high")} testId="high">
        <OhlcValue symbol={symbol} field="h" />
      </OhlcField>
      <OhlcField label={t("low")} testId="low">
        <OhlcValue symbol={symbol} field="l" />
      </OhlcField>
      <OhlcField label={t("close")} testId="close">
        <OhlcValue symbol={symbol} field="c" />
      </OhlcField>
    </Stack>
  )
}

interface OhlcValueProps {
  label: string
  testId: string
}

// TODO maybe merge this with InfoField as they are very similar apart from the variant
const OhlcField = ({
  label,
  children,
  testId,
}: PropsWithChildren<OhlcValueProps>) => {
  return (
    <Stack direction="row" gap={1}>
      <Typography variant="body1" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="body1" data-testid={testId}>
        <Subscribe fallback={<Skeleton width="50px" />}>{children}</Subscribe>
      </Typography>
    </Stack>
  )
}

const OhlcValue = ({ symbol, field }: DailyBarValueProps) => {
  return <DailyBarValue symbol={symbol} field={field} formatter={formatFloat} />
}
