import { Box, ButtonProps, CircularProgress, Tooltip } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { TRADE_TICKET_LOCATORS } from "@/mobile/components/tradeScreen/TradeTicket/locators"
import { directionToButtonColor } from "@/shared/components"
import { useInstrument } from "@/shared/services/instruments"
import {
  Direction,
  OrderType,
  submit,
  toggleDirection,
  useLimitPriceValue,
  useOrderType,
  useQtyValue,
  useResponse,
  useStopPriceValue,
} from "@/shared/services/orders"

import { StyledSubmitOrderButton } from "../OrderForm.styled"

const TooltipTitle = ({
  symbol,
  direction,
}: {
  symbol: string
  direction: string
}) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Box display="flex">
      <Box mr={0.5}>{direction}</Box>
      {displayName}
    </Box>
  )
}

const isEmpty = (value: string): boolean => !value || value === "0"

interface SubmitOrderButtonProps extends ButtonProps {
  direction: Direction
  symbol: string
}

export const SubmitOrderButton = ({
  direction,
  symbol,
  ...props
}: SubmitOrderButtonProps) => {
  const { t } = useTranslation()
  const type = useOrderType()
  const qtyValue = useQtyValue()
  const stopPriceValue = useStopPriceValue()
  const limitPriceValue = useLimitPriceValue()

  const { isLoading } = useResponse()

  const showLoading = isLoading && (
    <CircularProgress color="secondary" sx={{ mr: -2 }} size="15px" />
  )

  useEffect(() => {
    toggleDirection(direction)
  }, [direction])

  const checkStopOrderValue = isEmpty(stopPriceValue) || isEmpty(qtyValue)
  const checkLimitOrderValue = isEmpty(limitPriceValue) || isEmpty(qtyValue)

  const orderTypeChecks = {
    [OrderType.MarketOrder]: isEmpty(qtyValue),
    [OrderType.LimitOrder]: checkLimitOrderValue,
    [OrderType.StopOrder]: checkStopOrderValue,
    [OrderType.StopLimitOrder]: checkStopOrderValue,
  }

  const disabled = orderTypeChecks[type] || false

  const onClick = () =>
    submit({
      symbol,
      direction,
      type,
    })

  return (
    <Box display="flex" paddingY={2} width="100%">
      <Tooltip
        title={<TooltipTitle symbol={symbol} direction={t(direction)} />}
      >
        <Box display="flex" flex="1">
          <StyledSubmitOrderButton
            direction={direction}
            color={directionToButtonColor(direction)}
            data-testid={TRADE_TICKET_LOCATORS.tradeButton}
            disabled={disabled || isLoading || !symbol}
            onClick={onClick}
            endIcon={showLoading}
            {...props}
          >
            {t(direction)} {symbol}
          </StyledSubmitOrderButton>
        </Box>
      </Tooltip>
    </Box>
  )
}
