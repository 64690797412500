import { ThemeProvider as MuiThemeProvider } from "@mui/material"
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react"

import { themes } from "@/shared/theme"

import { ThemeMode } from "./ThemeMode"

const STORAGE_KEY = "themeMode"

interface ContextValue {
  themeMode: ThemeMode
  setThemeMode: Dispatch<SetStateAction<ThemeMode>>
}

export const ThemeContext = createContext<ContextValue>({
  themeMode: ThemeMode.DARK,
  // eslint-disable-next-line no-console
  setThemeMode: () => console.warn("Missing StorageThemeProvider"),
})

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>(
    (localStorage.getItem(STORAGE_KEY) ?? ThemeMode.DARK) as ThemeMode,
  )

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, themeMode)
  }, [themeMode])

  return (
    <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
      <MuiThemeProvider theme={themes[themeMode]}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  )
}
