import { Tab, Tabs } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { RootPath } from "@/desktop/paths"
import { useRouteMatch } from "@/shared/utils"

import { NAVIGATION_LOCATORS } from "./locators"

// To hide the Navigation on a given view, exclude it from this map
const routeTabMap = {
  [RootPath.trade]: RootPath.trade,
  [RootPath.positions]: RootPath.positions,
  [RootPath.tradeInstrument.routePattern]: RootPath.trade,
  [RootPath.orderHistory]: RootPath.orderHistory,
}

export const Navigation = () => {
  const { t } = useTranslation()
  const currentRoute = useRouteMatch(Object.keys(routeTabMap))
  if (currentRoute == null) {
    return null
  }

  const currentTab = routeTabMap[currentRoute]
  return (
    <Tabs
      value={currentTab}
      sx={{
        display: "flex",
        alignItems: "center",
        ".MuiTabs-flexContainer": { gap: 2.5 },
      }}
    >
      <Tab
        label={t("trade")}
        value={RootPath.trade}
        to={RootPath.trade}
        component={Link}
        data-testid={NAVIGATION_LOCATORS.trade}
      />
      <Tab
        label={t("myPositions")}
        value={RootPath.positions}
        to={RootPath.positions}
        component={Link}
        data-testid={NAVIGATION_LOCATORS.myPositions}
      />
      <Tab
        label={t("orderHistory")}
        value={RootPath.orderHistory}
        to={RootPath.orderHistory}
        component={Link}
        data-testid={NAVIGATION_LOCATORS.orderHistory}
      />
    </Tabs>
  )
}
