import { Stack, Typography } from "@mui/material"

import { InstrumentLogo } from "@/shared/components"
import { useInstrument } from "@/shared/services/instruments"

interface SymbolCellProps {
  symbol: string
  "data-testid": string
}
export const SymbolCell = ({
  symbol,
  "data-testid": testId,
}: SymbolCellProps) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack direction="row" alignItems="center">
      <Stack pr={1.5}>
        <InstrumentLogo
          symbol={symbol}
          variant="md"
          data-testid={`${testId}.logo`}
        />
      </Stack>
      <Stack>
        <Typography variant="body1">{displayName}</Typography>
        <Typography variant="body2" color="text.secondary">
          {symbol}
        </Typography>
      </Stack>
    </Stack>
  )
}
