import { Box, styled, Tab, Tabs } from "@mui/material"
import { ReactNode, SyntheticEvent, useCallback, useState } from "react"

import { useIsDesktop } from "@/shared/utils"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel = ({ children, value, index }: TabPanelProps) => {
  return (
    <Box display="flex" flexDirection="column" paddingTop={2} gap={1.5}>
      <Box role="tabpanel" hidden={value !== index}>
        {children}
      </Box>
    </Box>
  )
}

export interface OrderTab {
  key: string
  label: string
  content: ReactNode
  onSelect?: () => void
}

interface OrdertabsProps {
  orderTabs: OrderTab[]
}

const orderStyles = {
  Desktop: {
    minHeight: 42,
    fontSize: 12,
    minWidth: 65,
  },
  mobile: {
    minHeight: 64,
    fontSize: 20,
    minWidth: 75,
  },
}

const OrderTab = styled(Tab)(({ theme }) => {
  const isDesktop = useIsDesktop()

  const styles = isDesktop ? orderStyles["Desktop"] : orderStyles["mobile"]

  return {
    ...theme.typography.h3,
    textTransform: "capitalize",
    fontSize: styles.fontSize,
    minHeight: styles.minHeight,
    minWidth: styles.minWidth,
  }
})

export const OrderTabs = ({ orderTabs }: OrdertabsProps) => {
  const isDesktop = useIsDesktop()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = useCallback(
    (_: SyntheticEvent, index: number) => {
      const tab = orderTabs[index]

      if (tab?.onSelect) {
        tab.onSelect()
      }

      setTabIndex(index)
    },
    [orderTabs],
  )

  return (
    <>
      <Box>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          sx={{
            minHeight: (isDesktop
              ? orderStyles["Desktop"]
              : orderStyles["mobile"]
            ).minHeight,
          }}
          centered={!isDesktop}
        >
          {orderTabs.map(({ label, key }) => (
            <OrderTab label={label} key={key} />
          ))}
        </Tabs>
      </Box>

      {orderTabs.map(({ key, content }, index) => (
        <CustomTabPanel value={tabIndex} index={index} key={key}>
          {content}
        </CustomTabPanel>
      ))}
    </>
  )
}
