import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { SimpleViewLayout } from "@/mobile/layout"
import { LOGIN_LOCATORS, LoginForm } from "@/shared/components"

import { BottomBar } from "../utils"

export const Login = () => {
  const { pathname } = useLocation()

  const { t } = useTranslation()

  return (
    <SimpleViewLayout
      routePattern={pathname}
      title={<Typography variant="h1">{t("login")}</Typography>}
      data-testid={LOGIN_LOCATORS.root}
    >
      <LoginForm buttonWrapper={BottomBar} />
    </SimpleViewLayout>
  )
}
