import { Box, Skeleton } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import {
  BalanceDetails,
  ORDER_TABS,
  OrderErrorAlert,
  OrderTabs,
  SubmitOrderButton,
  TradeButton,
} from "@/shared/components"
import {
  Direction,
  toggleDirection,
  useTradeDirection,
} from "@/shared/services/orders"

interface Props {
  symbol: string
}

export const OrderForm = ({ symbol }: Props) => {
  const tradeDirection = useTradeDirection()

  return (
    <>
      <Box display="flex" gap={2} padding={2}>
        <Subscribe fallback={<Skeleton />}>
          <TradeButton
            disableElevation
            direction={Direction.Buy}
            sx={{
              height: 36,
            }}
            onClick={() => toggleDirection(Direction.Buy)}
          />
          <TradeButton
            disableElevation
            direction={Direction.Sell}
            sx={{
              height: 36,
            }}
            onClick={() => toggleDirection(Direction.Sell)}
          />
        </Subscribe>
      </Box>
      <OrderTabs orderTabs={ORDER_TABS} />
      <SubmitOrderButton
        disableElevation
        direction={tradeDirection}
        symbol={symbol}
      />
      <BalanceDetails />
      <OrderErrorAlert />
    </>
  )
}
