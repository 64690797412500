import { Box, Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import { CurrentPriceValue, InstrumentLogo, Star } from "@/shared/components"
import { useInstrument } from "@/shared/services/instruments"

interface InstrumentHeaderProps {
  symbol: string
  "data-testid": string
}

export const InstrumentHeader = ({
  symbol,
  "data-testid": testId,
}: InstrumentHeaderProps) => {
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack direction="row" gap={2}>
      <InstrumentLogo symbol={symbol} data-testid={`${"testId"}.logo`} />
      <Stack flexGrow={1} overflow="hidden">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Typography
            variant="h1"
            sx={{ flexGrow: 1 }}
            noWrap
            data-testid={`${testId}.displayName`}
          >
            <Subscribe fallback={<Skeleton />}>{displayName}</Subscribe>
          </Typography>
          <Box sx={{ flexShrink: 0, width: 30, height: 30 }}>
            <Star />
          </Box>
        </Stack>
        <Typography variant="h2">
          <Subscribe fallback={<Skeleton />}>
            <CurrentPriceValue symbol={symbol} />
          </Subscribe>
        </Typography>
      </Stack>
    </Stack>
  )
}
