import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import { Instrument } from "@/shared/services/TradingGateway"

import { InstrumentRow } from "./InstrumentRow"

interface InstrumentListProps {
  instruments: Instrument[]
  "data-testid": string
}

const defaultCellStyles = {
  color: "text.secondary",
  textTransform: "uppercase",
}

export const InstrumentsTable = ({
  instruments,
  "data-testid": testId,
}: InstrumentListProps) => {
  const { t } = useTranslation()

  return (
    <TableContainer sx={{ boxShadow: "none", width: "100%" }}>
      <Table
        sx={{ backgroundColor: "background.background0" }}
        aria-label="Instruments Table"
      >
        <TableHead>
          <TableRow
            sx={{
              border: "none",
              borderBottom: (theme) =>
                `solid ${theme.palette.background.background0}`,
            }}
          >
            <TableCell
              sx={{
                ...defaultCellStyles,
                minWidth: "125px",
              }}
            >
              {t("symbol")}
            </TableCell>
            <TableCell
              sx={{
                ...defaultCellStyles,
                minWidth: "80px",
              }}
              align="left"
            >
              {t("price")}
            </TableCell>
            <TableCell
              sx={{
                ...defaultCellStyles,
                minWidth: "60px",
              }}
              align="right"
            >
              {t("chg")}
            </TableCell>
            <TableCell
              sx={{
                ...defaultCellStyles,
                minWidth: "60px",
              }}
              align="left"
            >
              {t("24h%")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {instruments.map((row, index) => {
            const dataTestId = `${testId}.row.${index}`

            return (
              <InstrumentRow key={row.symbol} testId={dataTestId} row={row} />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
