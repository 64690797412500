import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import { useAccountSummary } from "@/shared/services/accountSummary"
import { formatCurrency } from "@/shared/utils"

import { HEADER_LOCATORS } from "./locators"

export const AccountSummary = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      data-testid={HEADER_LOCATORS.main}
    >
      <Typography
        variant="body1"
        data-testid={HEADER_LOCATORS.accountName}
        color="text.secondary"
      >
        Adaptive
      </Typography>

      <Typography
        variant="body1"
        data-testid={HEADER_LOCATORS.accountValue}
        color="text.secondary"
      >
        <Subscribe fallback={<Skeleton sx={{ width: "60px" }} />}>
          <AccountValue />
        </Subscribe>
      </Typography>
    </Stack>
  )
}

export const AccountValue = () => {
  return formatCurrency(useAccountSummary().portfolio_value)
}
