import { PropsWithChildren, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useIsDesktop, useIsLoginPage } from "@/shared/utils"

import { eventBus } from "./connection"
import { helloLogin } from "./login/service"
import {
  ConnectionState,
  setEmailLogged,
  setEventBusConnectionState,
} from "./login/signal"
import {
  useEmailLogged,
  useEventBusConnectionState,
  useIsEventBusTimeout,
} from "./login/state"

export const Authenticated = ({ children }: PropsWithChildren) => {
  const eventBusConnectionState = useEventBusConnectionState()

  const navigate = useNavigate()
  const isLoginPage = useIsLoginPage()
  const isDesktop = useIsDesktop()
  const emailLogged = useEmailLogged()
  const emailDataStore = localStorage.getItem("email")
  const isEventBusTimeout = useIsEventBusTimeout()

  const handleEventBusClose = useCallback(() => {
    setEventBusConnectionState(ConnectionState.DISCONNECTED)
    setEmailLogged(null)
    localStorage.removeItem("email")
    if (!isLoginPage) {
      isDesktop ? navigate("/desktop/login") : navigate("/mobile/login")
    }
  }, [navigate, isLoginPage, isDesktop])

  eventBus.onclose = handleEventBusClose

  useEffect(() => {
    if (
      eventBusConnectionState === ConnectionState.CONNECTED &&
      emailLogged === null
    ) {
      if (emailDataStore !== null && !isLoginPage) {
        helloLogin(emailDataStore)
      } else {
        isDesktop ? navigate("/desktop/login") : navigate("/mobile/login")
      }
    }

    if (isEventBusTimeout) {
      setEmailLogged(null)
    }
  }, [
    navigate,
    emailLogged,
    emailDataStore,
    eventBusConnectionState,
    isEventBusTimeout,
    isDesktop,
    isLoginPage,
  ])

  return children
}
