import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"

import { InstrumentLogo } from "@/shared/components"
import { useInstrument } from "@/shared/services/instruments"
import { OrderHistory } from "@/shared/services/TradingGateway"

interface Props extends OrderHistory {
  "data-testid": string
}

export const OrderHistoryListItem = ({
  symbol,
  side,
  qty,
  filledQty,
  status,
  "data-testid": testId,
}: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      padding={1}
      margin={-1}
      data-testid={testId}
    >
      <InstrumentLogo symbol={symbol} data-testid={`${testId}.logo`} />

      <Stack flexGrow={1} gap={0.5} overflow="hidden">
        <Stack direction="row" gap={1.5} alignItems="baseline">
          <Stack flexGrow={1} overflow="hidden">
            <Typography
              variant="body1"
              color="text.primary"
              noWrap
              data-testid={`${testId}.name`}
            >
              <Subscribe fallback={<Skeleton />}>
                {useInstrument(symbol)?.displayName}
              </Subscribe>
            </Typography>
          </Stack>
          <Typography
            variant="body1"
            color="text.primary"
            data-testid={`${testId}.side`}
            sx={{ textTransform: "capitalize" }}
          >
            Side: {side}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1.5} alignItems="baseline">
          <Typography
            flexGrow={1}
            variant="body1"
            color="text.secondary"
            data-testid={`${testId}.quantity`}
          >
            {filledQty}/{qty}
          </Typography>
          <Typography
            variant="body1"
            data-testid={`${testId}.status`}
            sx={{ textTransform: "capitalize" }}
          >
            {status}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
