import { t } from "i18next"

import { OrderType, toggleOrderType } from "@/shared/services/orders"

import { LimitOrderForm } from "./forms/LimitOrderForm"
import { MarketOrderForm } from "./forms/MarketOrderForm"
import { StopOrderForm } from "./forms/StopOrderForm"
import { OrderTab } from "./OrderTabs"

export const ORDER_TABS: OrderTab[] = [
  {
    key: "market",
    label: t("orderTabs.market"),
    content: <MarketOrderForm />,
    onSelect: () => toggleOrderType(OrderType.MarketOrder),
  },
  {
    key: "limit",
    label: t("orderTabs.limit"),
    content: <LimitOrderForm />,
    onSelect: () => toggleOrderType(OrderType.LimitOrder),
  },
  {
    key: "stop",
    label: t("orderTabs.stop"),
    content: <StopOrderForm />,
    onSelect: () => toggleOrderType(OrderType.StopOrder),
  },
]
