import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import {
  usePositionsValue,
  useProfitAndLoss,
} from "@/shared/services/positions"
import {
  formatCurrency,
  formatPercentageSigned,
  formatProfitAndLoss,
  getChangeTextColor,
} from "@/shared/utils"

import { MOBILE_POSITIONS_PAGE_LOCATORS } from "./locators"

export const PositionsHeader = () => {
  const { t } = useTranslation()

  return (
    <Stack gap={0.5}>
      <Typography variant="h1">{t("myPositions")}</Typography>
      <Stack flexDirection="row" gap={1.5}>
        <Subscribe fallback={<Skeleton width={50} />}>
          <PositionsValue />
        </Subscribe>
        <Subscribe fallback={<Skeleton width={50} />}>
          <ProfitAndLoss />
        </Subscribe>
      </Stack>
    </Stack>
  )
}

const PositionsValue = () => {
  const positionsValue = usePositionsValue()
  return (
    <Typography
      variant="h3"
      data-testid={MOBILE_POSITIONS_PAGE_LOCATORS.positionsValue}
    >
      {formatCurrency(positionsValue)}
    </Typography>
  )
}

const ProfitAndLoss = () => {
  const { pnl, pnlPercent } = useProfitAndLoss("total")

  return (
    <Typography
      variant="h3"
      color={getChangeTextColor({ change: pnl })}
      data-testid={MOBILE_POSITIONS_PAGE_LOCATORS.totalProfitLoss}
    >
      {formatProfitAndLoss(pnl)} ({formatPercentageSigned(pnlPercent)})
    </Typography>
  )
}
