import { useRegisterSW } from "virtual:pwa-register/react"

export const RegisterSW = () => {
  const { updateServiceWorker } = useRegisterSW({
    onNeedRefresh() {
      void updateServiceWorker(true)
    },
  })
  return null
}
