import { PaletteOptions, ThemeOptions } from "@mui/material"
import { TypographyOptions } from "@mui/material/styles/createTypography"

const muiTypography_AdaptiveLight: TypographyOptions = {
  h1: {
    fontFamily: "Roboto Mono",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h2: {
    fontFamily: "Roboto Mono",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: "-0.5px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h3: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "116.7%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h4: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "123.5%",
    letterSpacing: "0.25px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h5: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "133.4%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h6: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "143%",
    letterSpacing: "0.17px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "175%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle2: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "157%",
    letterSpacing: "0.1px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  overline: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "266%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  caption: {
    fontFamily: "Roboto",
    fontWeight: 800,
    fontSize: "12px",
    lineHeight: "166%",
    letterSpacing: "0.4px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
}

const muiPalette_AdaptiveLight: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#3b82f6",
    light: "#669df8",
    dark: "#0a59db",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#161a23",
    light: "#3c4760",
    dark: "#020303",
    contrastText: "#ffffff",
  },
  info: {
    main: "#0284c7",
    light: "#10acfd",
    dark: "#016395",
    contrastText: "#ffffff",
  },
  success: {
    main: "#16a34a",
    light: "#20e167",
    dark: "#107a38",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ea580c",
    light: "#f57b3b",
    dark: "#b04209",
    contrastText: "#ffffff",
  },
  error: {
    main: "#e11d48",
    light: "#e84e70",
    dark: "#a91636",
    contrastText: "#ffffff",
  },
  buy: {
    main: "#2563eb",
    light: "#5585ef",
    dark: "#1146bb",
    contrastText: "#ffffff",
  },
  sell: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  positive: {
    main: "#059669",
    light: "#08e29e",
    dark: "#04714f",
    contrastText: "#ffffff",
  },
  negative: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  neutral: {},
  action: {
    active: "#161a238f",
    hover: "#161a230a",
    selected: "#161a2314",
    focus: "#161a231f",
    disabled: "#161a2361",
    disabledBackground: "#161a231f",
  },
  background: {
    default: "#fff",
    background0: "#f9fafb",
    background1: "#f3f4f7",
    background2: "#ebedf2",
    background3: "#e4e7ed",
    background4: "#dce0e8",
    background5: "#d4d9e2",
    background6: "#ccd1dd",
    background7: "#c3cad7",
    background8: "#bbc2d2",
    background9: "#b3bbcc",
    background10: "#acb5c8",
    background11: "#9aa5bc",
    background12: "#8491ad",
    background13: "#7685a4",
    background14: "#6d7d9e",
    background15: "#627294",
    background16: "#5c6b8b",
    background17: "#53617d",
    background18: "#4d5a74",
    background19: "#3e485e",
    background20: "#2f3748",
    background21: "#252b38",
    background22: "#12151b",
    background23: "#030304",
    background24: "#000",
  },
  divider: "#161a231f",
  text: {
    primary: "#161a23de",
    secondary: "#161a2399",
    disabled: "#161a2361",
  },
  tertiary: {
    main: "#ffffff",
    light: "#fff",
    dark: "#e6e6e6",
    contrastText: "rgba(0,0,0, 0.87)",
  },
}

export const AdaptiveLight: ThemeOptions = {
  typography: muiTypography_AdaptiveLight,
  palette: muiPalette_AdaptiveLight,
  spacing: 4,
}

const muiTypography_AdaptiveDark: TypographyOptions = {
  h1: {
    fontFamily: "Roboto Mono",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h2: {
    fontFamily: "Roboto Mono",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: "-0.5px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h3: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "116.7%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h4: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "123.5%",
    letterSpacing: "0.25px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h5: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "133.4%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h6: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "143%",
    letterSpacing: "0.17px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "175%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle2: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "157%",
    letterSpacing: "0.1px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  overline: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "266%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  caption: {
    fontFamily: "Roboto",
    fontWeight: 800,
    fontSize: "12px",
    lineHeight: "166%",
    letterSpacing: "0.4px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
}

const muiPalette_AdaptiveDark: PaletteOptions = {
  mode: "dark",
  primary: {
    main: "#3b82f6",
    light: "#669df8",
    dark: "#0a59db",
    contrastText: "#fff",
  },
  secondary: {
    main: "#1f242e",
    light: "#0f1217",
    dark: "#1d212a",
    contrastText: "#ffffff",
  },
  info: {
    main: "#0284c7",
    light: "#10acfd",
    dark: "#016395",
    contrastText: "#ffffff",
  },
  success: {
    main: "#16a34a",
    light: "#20e167",
    dark: "#107a38",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ea580c",
    light: "#f57b3b",
    dark: "#b04209",
    contrastText: "#ffffff",
  },
  error: {
    main: "#e11d48",
    light: "#e84e70",
    dark: "#a91636",
    contrastText: "#ffffff",
  },
  buy: {
    main: "#2563eb",
    light: "#5585ef",
    dark: "#1146bb",
    contrastText: "#ffffff",
  },
  sell: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  positive: {
    main: "#059669",
    light: "#08e29e",
    dark: "#04714f",
    contrastText: "#ffffff",
  },
  negative: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  neutral: {},
  action: {
    active: "#ffffff8f",
    hover: "#ffffff14",
    selected: "#ffffff29",
    focus: "#ffffff1f",
    disabled: "#ffffff61",
    disabledBackground: "#ffffff1f",
  },
  background: {
    default: "#161a23",
    background0: "#1b202b",
    background1: "#1f2431",
    background2: "#222836",
    background3: "#262d3c",
    background4: "#293142",
    background5: "#2e374a",
    background6: "#39435b",
    background7: "#404c66",
    background8: "#495674",
    background9: "#516082",
    background10: "#58698d",
    background11: "#5c6d92",
    background12: "#63759d",
    background13: "#6e7fa4",
    background14: "#7989ab",
    background15: "#8593b2",
    background16: "#909cb9",
    background17: "#9ba6c0",
    background18: "#a6b0c7",
    background19: "#b1bace",
    background20: "#bcc4d5",
    background21: "#d2d8e3",
    background22: "#e9ebf1",
    background23: "#f4f5f8",
    background24: "#fff",
  },
  divider: "#ffffff1f",
  text: {
    primary: "#ffffffde",
    secondary: "#ffffff80",
    disabled: "#ffffff61",
  },
  tertiary: {
    main: "#111827",
    light: "#1f2b47",
    dark: "#080c14",
    contrastText: "rgba(255,255,255, 1)",
  },
}

export const AdaptiveDark: ThemeOptions = {
  typography: muiTypography_AdaptiveDark,
  palette: muiPalette_AdaptiveDark,
  spacing: 4,
}

const muiTypography_AlpacaLight: TypographyOptions = {
  h1: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: "-0.5px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h3: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "116.7%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h4: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "123.5%",
    letterSpacing: "0.25px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h5: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "133.4%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h6: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "143%",
    letterSpacing: "0.17px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "175%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle2: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "157%",
    letterSpacing: "0.1px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  overline: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "266%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  caption: {
    fontFamily: "Roboto",
    fontWeight: 800,
    fontSize: "12px",
    lineHeight: "166%",
    letterSpacing: "0.4px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
}

const muiPalette_AlpacaLight: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#000",
    light: "#383838",
    dark: "#000",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#737373",
    light: "#929292",
    dark: "#0b0b0b",
    contrastText: "#ffffff",
  },
  info: {
    main: "#0284c7",
    light: "#10acfd",
    dark: "#016395",
    contrastText: "#ffffff",
  },
  success: {
    main: "#16a34a",
    light: "#20e167",
    dark: "#107a38",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ea580c",
    light: "#f57b3b",
    dark: "#b04209",
    contrastText: "#ffffff",
  },
  error: {
    main: "#e11d48",
    light: "#e84e70",
    dark: "#a91636",
    contrastText: "#ffffff",
  },
  buy: {
    main: "#2563eb",
    light: "#5585ef",
    dark: "#1146bb",
    contrastText: "#ffffff",
  },
  sell: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  positive: {
    main: "#059669",
    light: "#08e29e",
    dark: "#04714f",
    contrastText: "#ffffff",
  },
  negative: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  neutral: {},
  action: {
    active: "#0a0a0a8f",
    hover: "#0a0a0a0a",
    selected: "#0a0a0a14",
    focus: "#0a0a0a1f",
    disabled: "#0a0a0a61",
    disabledBackground: "#0a0a0a1f",
  },
  background: {
    default: "#fff",
    background0: "#f9f9f9",
    background1: "#f2f2f2",
    background2: "#e8e8e8",
    background3: "#e2e2e2",
    background4: "#d8d8d8",
    background5: "#cfcfcf",
    background6: "#c6c6c6",
    background7: "#bdbdbd",
    background8: "#b4b4b4",
    background9: "#aaa",
    background10: "#a3a3a3",
    background11: "#969696",
    background12: "#868686",
    background13: "#7c7c7c",
    background14: "#757575",
    background15: "#6c6c6c",
    background16: "#656565",
    background17: "#5b5b5b",
    background18: "#555",
    background19: "#444",
    background20: "#343434",
    background21: "#292929",
    background22: "#141414",
    background23: "#030303",
    background24: "#000",
  },
  divider: "#0a0a0a1f",
  text: {
    primary: "#0a0a0ade",
    secondary: "#0a0a0a99",
    disabled: "#0a0a0a61",
  },
  tertiary: {
    main: "#ffffff",
    light: "#fff",
    dark: "#e6e6e6",
    contrastText: "rgba(0,0,0, 0.87)",
  },
}

export const AlpacaLight: ThemeOptions = {
  typography: muiTypography_AlpacaLight,
  palette: muiPalette_AlpacaLight,
  spacing: 4,
}

const muiTypography_AlpacaDark: TypographyOptions = {
  h1: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "20px",
    lineHeight: "150%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: "-0.5px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h3: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "116.7%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h4: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "123.5%",
    letterSpacing: "0.25px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h5: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "133.4%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  h6: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  body2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "143%",
    letterSpacing: "0.17px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "175%",
    letterSpacing: "0.15px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  subtitle2: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "157%",
    letterSpacing: "0.1px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  overline: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "266%",
    letterSpacing: "0",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
  caption: {
    fontFamily: "Roboto",
    fontWeight: 800,
    fontSize: "12px",
    lineHeight: "166%",
    letterSpacing: "0.4px",
    marginBottom: "0",
    textIndent: "0px",
    textTransform: "none",
    textDecoration: "none",
  },
}

const muiPalette_AlpacaDark: PaletteOptions = {
  mode: "dark",
  primary: {
    main: "#facc15",
    light: "#fbd748",
    dark: "#c7a004",
    contrastText: "#131313",
  },
  secondary: {
    main: "#171717",
    light: "#0c0c0c",
    dark: "#151515",
    contrastText: "#ffffff",
  },
  info: {
    main: "#0284c7",
    light: "#10acfd",
    dark: "#016395",
    contrastText: "#ffffff",
  },
  success: {
    main: "#16a34a",
    light: "#20e167",
    dark: "#107a38",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ea580c",
    light: "#f57b3b",
    dark: "#b04209",
    contrastText: "#ffffff",
  },
  error: {
    main: "#e11d48",
    light: "#e84e70",
    dark: "#a91636",
    contrastText: "#ffffff",
  },
  buy: {
    main: "#2563eb",
    light: "#5585ef",
    dark: "#1146bb",
    contrastText: "#ffffff",
  },
  sell: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  positive: {
    main: "#059669",
    light: "#08e29e",
    dark: "#04714f",
    contrastText: "#ffffff",
  },
  negative: {
    main: "#dc2626",
    light: "#e45656",
    dark: "#a71b1b",
    contrastText: "#ffffff",
  },
  neutral: {},
  action: {
    active: "#ffffff8f",
    hover: "#ffffff14",
    selected: "#ffffff29",
    focus: "#ffffff1f",
    disabled: "#ffffff61",
    disabledBackground: "#ffffff1f",
  },
  background: {
    default: "#0a0a0a",
    background0: "#111",
    background1: "#161616",
    background2: "#1b1b1b",
    background3: "#202020",
    background4: "#252525",
    background5: "#2c2c2c",
    background6: "#3b3b3b",
    background7: "#454545",
    background8: "#515151",
    background9: "#5d5d5d",
    background10: "#676767",
    background11: "#6c6c6c",
    background12: "#767676",
    background13: "#808080",
    background14: "#898989",
    background15: "#939393",
    background16: "#9d9d9d",
    background17: "#a7a7a7",
    background18: "#b1b1b1",
    background19: "#bababa",
    background20: "#c4c4c4",
    background21: "#d8d8d8",
    background22: "#ebebeb",
    background23: "#f5f5f5",
    background24: "#fff",
  },
  divider: "#ffffff1f",
  text: {
    primary: "#ffffffde",
    secondary: "#ffffff80",
    disabled: "#ffffff61",
  },
  tertiary: {
    main: "#111827",
    light: "#1f2b47",
    dark: "#080c14",
    contrastText: "rgba(255,255,255, 1)",
  },
}

export const AlpacaDark: ThemeOptions = {
  typography: muiTypography_AlpacaDark,
  palette: muiPalette_AlpacaDark,
  spacing: 4,
}
