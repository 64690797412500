import { Box, Skeleton, Stack, Typography } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import { Subscribe } from "@react-rxjs/core"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { LogoShort, ThemeToggle } from "@/shared/components"
import { useAccountSummary } from "@/shared/services/accountSummary"
import {
  ProfitAndLossType,
  useProfitAndLoss,
} from "@/shared/services/positions"
import {
  formatCurrency,
  formatPercentageSigned,
  formatProfitAndLoss,
  getChangeTextColor,
} from "@/shared/utils"

import { AppHeaderLocators } from "./locators"

const AccountNumber = () => {
  return useAccountSummary().account_number
}

const PortfolioBalance = () => {
  return formatCurrency(useAccountSummary().portfolio_value)
}

const CashBalance = () => {
  return formatCurrency(useAccountSummary().cash)
}

interface ProfitAndLossProps {
  type: ProfitAndLossType
  "data-testid": string
}

const ProfitAndLoss = ({ type, "data-testid": testId }: ProfitAndLossProps) => {
  const { pnl, pnlPercent } = useProfitAndLoss(type)
  return (
    <Typography
      variant="body2"
      color={getChangeTextColor({ change: pnl })}
      data-testid={testId}
    >
      {formatProfitAndLoss(pnl)} ({formatPercentageSigned(pnlPercent)})
    </Typography>
  )
}

export const AppHeader = () => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  return (
    <Box p={3} pb={0}>
      <Stack direction="row">
        <Stack
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          gap={2}
          flexGrow={1}
          data-testid={AppHeaderLocators.main}
          onClick={() => setExpanded(!expanded)}
        >
          <Box color="text.primary">
            <LogoShort />
          </Box>
          <Box>
            <Typography
              variant="h3"
              data-testid={AppHeaderLocators.accountName}
              color="text.primary"
            >
              Adaptive
            </Typography>

            <Typography
              pt={0.5}
              variant="h3"
              data-testid={AppHeaderLocators.accountInfo}
              color="text.primary"
            >
              <Subscribe
                fallback={
                  <Skeleton
                    sx={{ bgcolor: "background.background1", width: "100%" }}
                  />
                }
              >
                {expanded ? <AccountNumber /> : <PortfolioBalance />}
              </Subscribe>
            </Typography>
          </Box>
        </Stack>
        <ThemeToggle />
      </Stack>

      <Collapse in={expanded} data-testid={AppHeaderLocators.expanded}>
        <Stack gap={2} pt={3}>
          <Stack direction="row" gap={3}>
            <Box flexBasis="50%">
              <Typography pb={0.5} variant="h3" color="text.primary">
                {t("portfolioValue")}
              </Typography>
              <Typography
                variant="body2"
                data-testid={AppHeaderLocators.portfolioBalance}
                color="text.primary"
              >
                <Subscribe
                  fallback={
                    <Skeleton sx={{ bgcolor: "background.background1" }} />
                  }
                >
                  <PortfolioBalance />
                </Subscribe>
              </Typography>
            </Box>
            <Box flexBasis="50%">
              <Typography pb={0.5} variant="h3" color="text.primary">
                {t("cashValue")}
              </Typography>
              <Typography
                variant="body2"
                data-testid={AppHeaderLocators.cashBalance}
                color="text.primary"
              >
                <Subscribe
                  fallback={
                    <Skeleton sx={{ bgcolor: "background.background1" }} />
                  }
                >
                  <CashBalance />
                </Subscribe>
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" gap={3}>
            <Box flexBasis="50%">
              <Typography pb={0.5} variant="h3" color="text.primary">
                {t("totalGainLoss")}
              </Typography>
              <Subscribe
                fallback={
                  <Skeleton sx={{ bgcolor: "background.background1" }} />
                }
              >
                <ProfitAndLoss
                  type="total"
                  data-testid={AppHeaderLocators.totalGainLoss}
                />
              </Subscribe>
            </Box>
            <Box flexBasis="50%">
              <Typography pb={0.5} variant="h3" color="text.primary">
                {t("dailyGainLoss")}
              </Typography>
              <Subscribe
                fallback={
                  <Skeleton sx={{ bgcolor: "background.background1" }} />
                }
              >
                <ProfitAndLoss
                  type="daily"
                  data-testid={AppHeaderLocators.dailyGainLoss}
                />
              </Subscribe>
            </Box>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  )
}
