import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  StandardTextFieldProps,
} from "@mui/material"
import { ChangeEvent } from "react"

import { useIsDesktop, validateInput } from "@/shared/utils"

import { Icon } from "../icons"
import { SelectItem } from "./forms/timeInForceSelections"
import { OrderBox, OrderSelect, OrderTextField } from "./OrderForm.styled"

const DESKTOP_STYLES = {
  inputHeight: 24,
  inputFontSize: 14,
  labelFontSize: 11,
}

const MOBILE_STYLES = {
  inputHeight: 40,
  inputFontSize: 24,
  labelFontSize: 18,
}

export type OrderInputProps = Omit<StandardTextFieldProps, "onChange"> & {
  onChange: (validatedValue: string) => void
}

export const OrderInput = (props: OrderInputProps) => {
  const isDesktop = useIsDesktop()

  const { onChange, ...rest } = props

  const styles = isDesktop ? DESKTOP_STYLES : MOBILE_STYLES

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const validated = validateInput(event.target.value)
    event.target.value = validated
    onChange(validated)
  }

  return (
    <OrderBox paddingX={1.5} paddingY={1} flex={1}>
      <OrderTextField
        type="number"
        placeholder="0.00"
        inputProps={{ step: 0.001 }}
        onChange={changeHandler}
        sx={{ width: "100%" }}
        InputProps={{
          disableUnderline: true,
          sx: {
            height: styles.inputHeight,
            fontSize: styles.inputFontSize,
          },
          inputProps: {
            "data-testid": `input-${props.name}`,
          },
          endAdornment: !isDesktop && (props.value as string) && (
            <InputAdornment position="end">
              <IconButton onClick={() => onChange("")}>
                <Icon color="text.secondary" name="close" size="md" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </OrderBox>
  )
}

interface OrderSelectBoxProps {
  options: SelectItem[]
  name: string
  value: string
  label: string
  onChange: (e: SelectChangeEvent<unknown>) => void
}

export const OrderSelectBox = ({
  name,
  options,
  label,
  value,
  onChange,
}: OrderSelectBoxProps) => {
  const isDesktop = useIsDesktop()

  const desktopStyles = {
    selectHeight: 56,
    selectFontSize: 12,
    labelFontSize: 11,
    itemFontSize: 12,
  }

  const mobileStyles = {
    selectHeight: 72,
    selectFontSize: 24,
    labelFontSize: 18,
    itemFontSize: 16,
  }

  const styles = isDesktop ? desktopStyles : mobileStyles

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel
        sx={{
          fontSize: styles.labelFontSize,
          "&.Mui-focused": { color: "text.secondary" },
        }}
        id={`${name}-label`}
      >
        {label}
      </InputLabel>
      <OrderSelect
        disableUnderline
        sx={{ height: styles.selectHeight, fontSize: styles.selectFontSize }}
        labelId={`${name}-label`}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {options.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
            sx={{ fontSize: styles.itemFontSize }}
          >
            {label}
          </MenuItem>
        ))}
      </OrderSelect>
    </FormControl>
  )
}
