import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import {
  instrumentInfo,
  InstrumentInfoDataPoint,
  QuoteDataPoint,
  QuoteDataPointField,
} from "@/shared/components"
import { Instrument } from "@/shared/services/TradingGateway"

import { InfoField } from "../InfoField"

interface Props {
  symbol: string
}

export const InstrumentDetails = ({ symbol }: Props) => {
  const { t } = useTranslation()

  return (
    <Stack p={2.75} gap={1.5} data-testid="InstrumentDetails">
      <Typography variant="h2">{t("keyInfo")}</Typography>
      <Stack direction="row" flexWrap="wrap" gap={4}>
        {instrumentInfo.map((info) => {
          const { label, type, field } = info

          return (
            <InfoField label={label} key={field} testId={field}>
              {type === "quote" && (
                <QuoteDataPoint
                  field={field as QuoteDataPointField}
                  symbol={symbol}
                />
              )}

              {type === "instrumentInfo" && (
                <InstrumentInfoDataPoint
                  field={field as keyof Instrument}
                  symbol={symbol}
                  formatter={info.formatter}
                />
              )}
            </InfoField>
          )
        })}
      </Stack>
    </Stack>
  )
}
