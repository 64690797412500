import { useEffect, useState } from "react"

import { useResponse } from "@/shared/services/orders"
import { OrderStatus } from "@/shared/services/TradingGateway/OrderService"

import { SnackbarMessage } from "../../SnackbarMessage"
import { SnackbarMessageType } from "../../SnackbarMessageType"

export const OrderErrorAlert = () => {
  const { isLoading, response } = useResponse()

  const [showToaster, setShowToaster] = useState(false)

  useEffect(() => {
    if (!isLoading && response) {
      if (response.type !== OrderStatus.accepted) {
        setShowToaster(true)
      }
    }
  }, [isLoading, response])

  const handleCloseToaster = () => {
    setShowToaster(false)
  }

  return (
    <SnackbarMessage
      type={SnackbarMessageType.ERROR}
      isOpen={showToaster}
      onClose={handleCloseToaster}
      message={response?.error?.message || response?.error?.msg?.message || ""}
    />
  )
}
