import { Typography } from "@mui/material"

import { usePriceAndChange } from "@/shared/services/instruments"
import { formatCurrency, formatPercentageSigned } from "@/shared/utils"

interface ValueProps {
  symbol: string
  testId?: string
}

export const CurrentPriceValue = ({ symbol }: ValueProps) => {
  const { price } = usePriceAndChange(symbol)
  return formatCurrency(price)
}

export const ChangePercentage = ({ symbol, testId }: ValueProps) => {
  const { change, changeAmount } = usePriceAndChange(symbol)
  return (
    <Typography
      variant="body2"
      color={changeAmount < 0 ? "negative.main" : "positive.main"}
      data-testid={testId}
    >
      {formatPercentageSigned(change)}
    </Typography>
  )
}

export const ChangeAmount = ({ symbol, testId }: ValueProps) => {
  const { changeAmount } = usePriceAndChange(symbol)
  return (
    <Typography
      variant="body2"
      color={changeAmount < 0 ? "negative.main" : "positive.main"}
      data-testid={testId}
    >
      {formatCurrency(changeAmount)}
    </Typography>
  )
}
