import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import { CashValue, PortfolioValue } from "@/shared/components"
import {
  ProfitAndLossType,
  usePositionsValue,
  useProfitAndLoss,
} from "@/shared/services/positions"
import {
  formatCurrency,
  formatPercentageSigned,
  formatProfitAndLoss,
  getChangeTextColor,
} from "@/shared/utils"

import { InfoField } from "./InfoField"

interface SummaryHeaderProps {
  title: string
}

export const SummaryHeader = ({ title }: SummaryHeaderProps) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap={5} alignItems="center">
      <Stack mr={4}>
        <Typography variant="h1">{t(title)}</Typography>
        <Typography variant="h3" color="text.secondary" data-testid="date">
          {new Date().toDateString()}
        </Typography>
      </Stack>
      <InfoField label={t("totalPortfolioValue")} testId="totalPortfolioValue">
        <PortfolioValue />
      </InfoField>
      <InfoField label={t("invested")} testId="invested">
        <Invested />
      </InfoField>
      <InfoField label={t("cashValue")} testId="cashValue">
        <CashValue />
      </InfoField>
      <InfoField label={t("totalGainLoss")} testId="totalGainLoss">
        <ProfitAndLoss type="total" />
      </InfoField>
      <InfoField label={t("dailyGainLoss")} testId="dailyGainLoss">
        <ProfitAndLoss type="daily" />
      </InfoField>
    </Stack>
  )
}

const Invested = () => {
  return formatCurrency(usePositionsValue())
}

const ProfitAndLoss = ({ type }: { type: ProfitAndLossType }) => {
  const { pnl, pnlPercent } = useProfitAndLoss(type)
  const color = getChangeTextColor({ change: pnl })
  return (
    <Typography component="span" variant="inherit" color={color}>
      {formatProfitAndLoss(pnl)} ({formatPercentageSigned(pnlPercent)})
    </Typography>
  )
}
