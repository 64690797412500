import { Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import { SimpleViewLayout } from "@/mobile/layout"
import { RootPath } from "@/mobile/paths"
import { ErrorBoundary, Loading } from "@/shared/components"
import { useOrderHistory } from "@/shared/services/orderHistory"

import { List } from ".."
import { ORDER_HISTORY_PAGE_LOCATORS } from "./locators"
import { OrderHistoryListItem } from "./OrderHistoryListItem"

export const OrderHistory = () => {
  const { t } = useTranslation()
  return (
    <SimpleViewLayout
      data-testid="order-history-layout"
      routePattern={RootPath.orderHistory}
      title={<Typography variant="h1">{t("orderHistory")}</Typography>}
    >
      <ErrorBoundary fallback={<div>{t("errorLoadingOrderHistory")}</div>}>
        <Subscribe fallback={<Loading />}>
          <OrderHistoryList />
        </Subscribe>
      </ErrorBoundary>
    </SimpleViewLayout>
  )
}

const OrderHistoryList = () => {
  const orderHistory = useOrderHistory()

  return (
    <List data={orderHistory} keyField="id">
      {(orderHistory, index) => (
        <OrderHistoryListItem
          {...orderHistory}
          data-testid={`${ORDER_HISTORY_PAGE_LOCATORS.main}.row.${index}`}
        />
      )}
    </List>
  )
}
