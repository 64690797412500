import { Skeleton, Stack, Typography } from "@mui/material"
import { Subscribe } from "@react-rxjs/core"
import { useTranslation } from "react-i18next"

import { CurrentPriceValue, InstrumentLogo } from "@/shared/components"
import { useInstrument, usePriceAndChange } from "@/shared/services/instruments"
import {
  formatCurrency,
  formatInteger,
  formatPercentageSigned,
} from "@/shared/utils"

import { InfoField } from "../InfoField"
import { DailyBarValue } from "./DailyBarValue"

interface InstrumentHeaderProps {
  symbol: string
  "data-testid": string
}

export const InstrumentHeader = ({
  symbol,
  "data-testid": testId,
}: InstrumentHeaderProps) => {
  const { t } = useTranslation()
  const displayName = useInstrument(symbol)?.displayName

  return (
    <Stack flexDirection="row" alignItems="center" gap={2} px={4} py={4}>
      <InstrumentLogo
        variant="lg"
        symbol={symbol}
        data-testid={`${"testId"}.logo`}
      />
      <Stack flex="1 1">
        <Typography
          variant="h2"
          maxWidth={160}
          data-testid={`${testId}.displayName`}
        >
          <Subscribe fallback={<Skeleton />}>{displayName}</Subscribe>
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          data-testid={`${testId}.symbol`}
        >
          {symbol}
        </Typography>
      </Stack>
      <Stack direction="row" ml={5} gap={5}>
        <InfoField label={t("lastPrice")} testId="lastPrice">
          <CurrentPriceValue symbol={symbol} />
        </InfoField>
        <InfoField label={t("24hPriceChange")} testId="24hPriceChange">
          <Change symbol={symbol} />
        </InfoField>
        <InfoField label={t("24hHigh")} testId="24hHigh">
          <DailyBarValue symbol={symbol} field="h" formatter={formatCurrency} />
        </InfoField>
        <InfoField label={t("24hLow")} testId="24hLow">
          <DailyBarValue symbol={symbol} field="l" formatter={formatCurrency} />
        </InfoField>
        <InfoField label={t("24hVolume")} testId="24hVolume">
          <DailyBarValue symbol={symbol} field="v" formatter={formatInteger} />
        </InfoField>
      </Stack>
    </Stack>
  )
}

const Change = ({ symbol }: { symbol: string }) => {
  const { change, changeAmount } = usePriceAndChange(symbol)
  return (
    <Typography
      variant="inherit"
      component="span"
      color={changeAmount < 0 ? "negative.main" : "positive.main"}
    >
      {formatCurrency(changeAmount)} ({formatPercentageSigned(change)})
    </Typography>
  )
}
